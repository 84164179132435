/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.nav-trigger').bind('click touchstart', function(event) {
          event.preventDefault();

          $('body').toggleClass('nav-popup-visible');
          $(this).toggleClass('active');
          $('.mobile-nav-wrapper').toggleClass('active');
        });

        var canclickmenuslide = true;
        $('.slide-down-menu > a').bind('click touchstart', function(event) {
          event.preventDefault();
          if (canclickmenuslide) {
            canclickmenuslide = false;
            $(this).parent().toggleClass('active').find('.sub-menu').slideToggle(400, function() {
              // Animation complete.
              canclickmenuslide = true;
            });
          }
        });

        var canclickslide = true;
        $('.accordion-trigger').on('click', function() {
          if (canclickslide) {
            canclickslide = false;
            $(this).toggleClass('active').next('.accordion-slide-content').slideToggle(400, function() {
              // Animation complete.
              canclickslide = true;
            });
          }
        });

        $('.articles-carousel').slick({
          arrows: false,
          dots: true,
          //useTransform: false,
          //variableWidth: true,
          slidesToShow: 3,
          rows: 0,
          //slide: 'gallery-item',
          centerPadding: 0,
          slidesToScroll: 3,
          centerMode: false,
          responsive: [
             {
               breakpoint: 1024,
               settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1
               }
             },
             {
               breakpoint: 768,
               settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
               }
             }
           ]
        });

        $('.videos-carousel').slick({
          arrows: false,
          dots: true,
          //useTransform: false,
          //variableWidth: true,
          slidesToShow: 2,
          rows: 0,
          //slide: 'gallery-item',
          centerPadding: 0,
          slidesToScroll: 1,
          centerMode: false,
          responsive: [
             {
               breakpoint: 768,
               settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
               }
             }
           ]
        });

        // modal galleries
        var gallerymodal = $('.gallery-modal');
        var gallerylist = $('.gallery-link-list');
        var controlstarget;

        function openGalleryModal() {
          controlstarget = $(this).attr('data-target');
          $(controlstarget).addClass('show-modal');
          $('body').addClass('gallery-visible');
          var maintarget = $(controlstarget).find('.gallery-carousel-main');
          var thumbtarget = $(controlstarget).find('.gallery-carousel-thumbs');
          maintarget.slick({
           slidesToShow: 1,
           slidesToScroll: 1,
           arrows: false,
           lazyLoad: 'ondemand',
           fade: true,
           asNavFor: thumbtarget
          });
          thumbtarget.slick({
           slidesToShow: 3,
           slidesToScroll: 1,
           asNavFor: maintarget,
           dots: true,
           arrows: false,
           lazyLoad: 'ondemand',
           centerMode: true,
           centerPadding: 0,
           focusOnSelect: true,
           responsive: [
              {
                breakpoint: 680,
                settings: {
                  slidesToShow: 2
                }
              }
            ]
          });
        }

        function closeGalleryModal() {
          $(controlstarget).removeClass('show-modal');
          $('body').removeClass('gallery-visible');
          setTimeout(function() {
            $(controlstarget).find('.gallery-carousel-main').slick('unslick');
            $(controlstarget).find('.gallery-carousel-thumbs').slick('unslick');
          }, 500);
        }

        gallerylist.on('click', '.gallery-link', openGalleryModal);
        gallerylist.on('click', '.close-button', closeGalleryModal);

        gallerylist.on('click', '.slider-left', function() {
          $(controlstarget).find('.gallery-carousel-main').slick('slickPrev');
        });

        gallerylist.on('click', '.slider-right', function() {
          $(controlstarget).find('.gallery-carousel-main').slick('slickNext');
        });

        var popupmodal = $('.popup-modal');
        var popuptarget;

        function openPopupModal() {
          popuptarget = $(this).attr('data-target');
          $(popuptarget).addClass('show-modal');
          $('body').addClass('modal-visible');
        }

        function closePopupModal() {
          popuptarget = $(this).attr('data-target');
          $(popuptarget).removeClass('show-modal');
          $('body').removeClass('modal-visible');
        }

        $('.popup-button').on('click', openPopupModal);
        $('.close-button').on('click', closePopupModal);


        $('.partner-logo-carousel').slick({
          arrows: false,
          dots: false,
          //useTransform: false,
          //variableWidth: true,
          slidesToShow: 3,
          autoPlay: true,
          rows: 0,
          //slide: 'gallery-item',
          centerPadding: 0,
          slidesToScroll: 1,
          centerMode: false,
          responsive: [
             {
               breakpoint: 1024,
               settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1
               }
             },
             {
               breakpoint: 480,
               settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
               }
             }
           ]
        });

        $('.partner-logo-carousel-left').on('click', function() {
          $('.partner-logo-carousel').slick('slickPrev');
        });
        $('.partner-logo-carousel-right').on('click', function() {
          $('.partner-logo-carousel').slick('slickNext');
        });

        $('.text-partner-logo-carousel').slick({
          arrows: false,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          centerMode: false,
          rows: 0,
          centerPadding: 0
        });

        $('.text-partner-logo-carousel-left').on('click', function() {
          $('.text-partner-logo-carousel').slick('slickPrev');
        });
        $('.text-partner-logo-carousel-right').on('click', function() {
          $('.text-partner-logo-carousel').slick('slickNext');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // landing page
    'page_template_template_landing': {
      init: function() {
        $('#landing-carousel-1').slick({
          arrows: false,
          dots: false,
          autoplay: true,
          //useTransform: false,
          //variableWidth: true,
          slidesToShow: 1,
          rows: 0,
          //slide: 'gallery-item',
          centerPadding: 0,
          slidesToScroll: 1,
          centerMode: false
        });

        $('.landing-carousel-1-left').on('click', function() {
          $('#landing-carousel-1').slick('slickPrev');
        });

        $('.landing-carousel-1-right').on('click', function() {
          $('#landing-carousel-1').slick('slickNext');
        });

        $('#landing-carousel-2').slick({
          arrows: false,
          dots: false,
          autoplay: true,
          //useTransform: false,
          //variableWidth: true,
          slidesToShow: 1,
          rows: 0,
          //slide: 'gallery-item',
          centerPadding: 0,
          slidesToScroll: 1,
          centerMode: false
        });

        $('.landing-carousel-2-left').on('click', function() {
          $('#landing-carousel-2').slick('slickPrev');
        });

        $('.landing-carousel-2-right').on('click', function() {
          $('#landing-carousel-2').slick('slickNext');
        });

      },
      finalize: function() {
        if($('#popup-home').length){
          if(localStorage.getItem('popState') != 'shown'){
            $('#popup-home').addClass('show-modal');
            $('body').addClass('modal-visible');
            localStorage.setItem('popState','shown')
          }
        }
      }
    },
    // Home page
    'page_template_template_home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.social-feed-trigger').bind('click touchstart', function(event) {
          event.preventDefault();
          $('.home-feed-wrapper').toggleClass('active');
        });

        $('.home-carousel').slick({
          arrows: false,
          dots: false,
          //useTransform: false,
          //variableWidth: true,
          slidesToShow: 3,
          rows: 0,
          //slide: 'gallery-item',
          centerPadding: 0,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          centerMode: false,
          responsive: [
             {
               breakpoint: 1024,
               settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1
               }
             },
             {
               breakpoint: 480,
               settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
               }
             }
           ]
        });

        $('.home-carousel-left').on('click', function() {
          $('.home-carousel').slick('slickPrev');
        });

        $('.home-carousel-right').on('click', function() {
          $('.home-carousel').slick('slickNext');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        if($('#popup-home').length){
          if(localStorage.getItem('popState') != 'shown'){
            $('#popup-home').addClass('show-modal');
            $('body').addClass('modal-visible');
            localStorage.setItem('popState','shown')
          }
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
